/*
 * 업무구분 : 고객
 * 화면 명 : MSPCM502M
 * 화면 설명: 지류동의승인현황 
 */
 <template>
  <ur-page-container class="msp" :show-title="false" title="지류동의승인현황" :topButton="false" ref="page" @on-scroll-bottom="fn_LoadingData">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '지류동의승인현황' : '지류동의승인현황'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt30 pb10">
              <div class="inline-label__wrap full">
                <span class="tit">조회기준</span>
                <div class="add-date tit-inline__wrap">
                  <mo-date-picker bottom v-model="beginDate" @input="fn_dateCheck" :min-date="disabledDate.min" :max-date="disabledDate.max" placeholder="선택일" class="ns-date-picker" />
                  <span class="tilde"></span>
                  <mo-date-picker bottom v-model="endDate" @input="fn_dateCheck" :min-date="disabledDate.min" :max-date="disabledDate.max" placeholder="선택일" class="ns-date-picker" />
                </div>
              </div>
              <div class="inline-label__wrap full mt20">
                <span class="tit">컨설턴트</span>
                <span>
                  <mo-text-field :disabled="chnlScCd" class="form-input-name full" searchable underline placeholder="" @click="fn_openMSPBC410P" v-model="vCnsltInfo" />
                </span>
              </div>
              <div class="inline-label__wrap full mt20">
                <span class="tit">동의서</span>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                  <mo-segment-wrapper solid primary v-model="custWcnstTypCd" class="chip-type-wrap chip-ty1">
                    <mo-segment-button value="01">필수컨설팅</mo-segment-button>
                    <mo-segment-button value="02">지급내역</mo-segment-button>
                  </mo-segment-wrapper>
                </ur-box-container>
              </div>
            </ur-box-container>
          </template> 
        
          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
              <mo-list-item>
                <div class="list-item__contents__title">
                  <span class="sum">총 <strong class="crTy-blue3">{{totalCnt}}</strong> 명</span>
                </div>
              </mo-list-item>
            </ur-box-container>

            <ur-box-container v-if="noData" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list types4 list-pa2024">
              <mo-list :list-data="cmGaCustMngVO">
                <template #list-item="{item}">
                  <mo-list-item :class="{'on' : item.data === true}" class="bd-b-Ty1">
                    <!-- <mo-checkbox v-model="item.data" /> --><!-- 2024.03.26 list check-box 삭제 -->
                    <div class="list-item__contents">
                      <div class="list-item__contents__title">
                        <span class="name txtSkip">{{item.mobslChnlCustNm}}</span>
                        <mo-badge v-if="item.aprvScCd !== '00'" class="badge-sample-badge green sm" text="승인" shape="status">승인</mo-badge> 
                        <mo-badge v-else class="badge-sample-badge red sm" text="미승인" shape="status">미승인</mo-badge> 
                      </div>
                      <div class="list-item__contents__info row">
                        <span class="tit">동의서일련번호</span>
                        <span class="info">{{item.vuchId}}</span>
                      </div>
                      <div class="list-item__contents__info row">
                        <span class="tit">승인구분</span>
                        <!-- 승인구분코드 : aprvScCd -->
                        <span class="info">{{item.aprvValue}}</span>
                      </div>
                      <div class="list-item__contents__info row">
                        <span class="tit">승인일자</span>
                        <!-- 승인일자 :aprvYmd -->
                        <span class="info">{{item.aprvYmd}}</span>
                      </div>
                      <div class="list-item__contents__info row">
                        <span class="tit">담당 컨설턴트</span>
                        <span class="info">{{item.eplyNmNo}}</span>
                      </div>
                    </div>
                  </mo-list-item>
                </template>
              </mo-list>
            </ur-box-container>

            <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info hauto pt20 pb20">
                    <span class="ns-ftcr-gray"><p class="mt10">조회결과가 없습니다.</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>

            <!-- <template>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
                <div class="relative-div">
                  <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue">조회</mo-button>
                </div>
              </ur-box-container>
            </template> -->
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import MSPBC410P from '@/ui/bc/MSPBC410P'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "",
    screenId: "",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_init()
    },
    mounted() {
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {

        // 조회조건
        endDate                     : '',    // 종료일
        beginDate                   : '',    // 시작일
        dateCheck                   : true,  // 날짜 설정 체크
        custWcnstTypCd              : "01",  // 동의서타입
        nextKey                     : '0',
        isFinished                  : false, // 남은 데이터 존재 여부

        // 화면변수
        userInfo                    : {},
        eplyNo                      : '',    // 컨설턴트ID
        gssEplyNm                   : '',    // 컨설턴트 이름
        noData                      : true,
        chnlScCd                    : '',    // 채널고객 코드
        lv_HeaderviewScrollCompID   : '',
        cmGaCustMngVO               : [],    // 조회 데이터
        totalCnt                    : '0',   // 조회 데이터 수
      };
    },

    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      // 동의서 타입 변경 시, 재조회
      custWcnstTypCd () {
        this.fn_changeSearch()
      },

      // 컨설턴트 구분 코드
      'userInfo.chnlScCd' (){ 
        if(this.userInfo.chnlScCd === 'GA') {
          this.chnlScCd = false
        } else {
          this.chnlScCd = true
        }
      }
    },
    
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

      // 조회기준 : 오늘기준 3개월 전까지
      disabledDate () {
        return {
          min: this.$bizUtil.dateDashFormat(moment(new Date(moment().add(-3, 'months'))).format('YYYYMMDD')),
          max: this.$bizUtil.dateDashFormat(moment(new Date()).format('YYYYMMDD'))
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
    /******************************************************************************
     * @function fn_init
     * @name     초기화
    ******************************************************************************/
      fn_init() {
        // 조회기준
        this.beginDate = moment(new Date()).format('YYYY-MM-01')
        this.endDate   = moment(new Date()).format('YYYY-MM-DD')

        // 사용자 정보
        this.userInfo   = this.getStore('userInfo').getters.getUserInfo
        this.vCnsltInfo = this.userInfo.userNm + "(" + this.userInfo.userId + ")"
        this.eplyNo     = this.userInfo.userId
        this.gssEplyNm  = this.userInfo.userNm
        // 조회 변수
        this.cmGaCustMngVO = []

        this.fn_search()
      },
    /******************************************************************************
     * @function fn_search
     * @name     지류동의승인현황 조회
    ******************************************************************************/
      fn_search() {

        // 조회 날짜 체크
        if(!this.dateCheck) {
          this.fn_alertOpen(this.$t('cm')['ECMC087'],)
          return
        }
        
        // 조회 조건 세팅
        let pParams = {
          'userEno'       : this.eplyNo,                       // 사번
          'custWcnstTypCd': this.custWcnstTypCd,               // 동의서구분코드
          'toDt'          : this.endDate.replace(/-/gi, ''),   // 종료일자
          'fromDt'        : this.beginDate.replace(/-/gi, ''), // 시작일자
          'pageSize'      : 20,                                // 조회 데이터 개수
          'nextKey'       : this.nextKey,
        }

        const trnstId = 'txTSSCM00S1'
        const auth    = 'S'

        this.post(this, pParams, trnstId, auth)
          .then(response => {
            if ( response.body ) {
              let cmGaCustMngList = response.body.gaCustMngList
              
              // 추가 조회 데이터 여부
              if(response.body.nextKey === null) {
                this.isFinished = true
              }
              
              // 조회 데이터 세팅
              cmGaCustMngList.forEach(item => {
                let dataObject       = {}
                if(this.custWcnstTypCd === "01") {
                  dataObject.vuchId    = item.cnsltWcnstPblId                                  // 동의서일련번호
                } else {
                  dataObject.vuchId    = item.pymBrkdnCnsntNo
                }
                dataObject.aprvScCd  = item.aprvScCd                                           // 승인구분코드
                if(dataObject.aprvScCd == '00') {
                  dataObject.aprvValue = '미승인'
                } else if(dataObject.aprvScCd == '01') {
                  dataObject.aprvValue = '자동승인'
                } else {
                  dataObject.aprvValue = '직접승인'
                }
                if(!this.$bizUtil.isEmpty(item.aprvYmd)) {
                  dataObject.aprvYmd = this.$cmUtil.frmtDateStr(item.aprvYmd, 'yyyy-mm-dd')    // 승인일자
                } else {
                  dataObject.aprvYmd = '-'
                }

                dataObject.mobslChnlCustNm = item.mobslChnlCustNm                              // 고객명
                dataObject.eplyNmNo  = this.gssEplyNm + "(" + this.eplyNo + ")"                // 담당컨설턴트

                this.cmGaCustMngVO.push(dataObject)
              })

              // 총 데이터 수
              this.totalCnt = this.cmGaCustMngVO.length
              
              // 다음 조회 값
              this.nextKey = response.body.nextKey

            } else {
              this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(error => {
            window.vue.error(error)
          })
      },
    /******************************************************************************
     * @function fn_openMSPBC410P
     * @name     컨설턴트 선택 팝업 호출
    ******************************************************************************/
      fn_openMSPBC410P(){
        let lv_Vm = this
        this.popupBC410P = this.$bottomModal.open(MSPBC410P, {
          properties: {
          },
          listeners: {
            // 확인
            onPopupConfirm: (pData) => {
              console.log('팝업 파라미터>>>>', pData)

              lv_Vm.eplyNo     = pData.eplyNo                                  // 컨설턴트ID
              lv_Vm.gssEplyNm  = pData.gssEplyNm                               // 컨설턴트명
              lv_Vm.vCnsltInfo = pData.gssEplyNm + "(" + pData.eplyNo + ")"

              this.getStore('cmStore').dispatch('GSS_EPLY_INFO', pData)
              lv_Vm.fn_changeSearch()
              lv_Vm.$bottomModal.close(lv_Vm.popupBC410P)
            },
            // 닫기
            onPopupClose: () => {
              console.log('팝업 닫기>>>>')
              lv_Vm.$bottomModal.close(lv_Vm.popupBC410P)
            }
          }
        },
        {
          properties : {noHeader : true}
        })
      },

    /*******************************************************************************
     * @function fn_dateCheck
     * @name     시작일, 종료일 체크
     *******************************************************************************/
      fn_dateCheck() {
        let end   = this.endDate.replace(/-/gi, '')
        let begin = this.beginDate.replace(/-/gi, '')

        if(begin > end) { 
          this.fn_alertOpen(this.$t('cm')['ECMC087'],)
          this.dateCheck = false
        } else {
          this.fn_changeSearch()
          this.dateCheck = true
        }
      },
    /*********************************************************
      * @function fn_alertOpen
      * @name     알럿 오픈
      *********************************************************/
      fn_alertOpen(content) {
        this.confirmPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single        : true,
            title         : "알림",
            content       : content,
            title_pos_btn : "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.confirmPop);
            }
          }
        })
      },
    /*********************************************************
     * @function fn_alertOpen
     * @name     알럿 오픈
     *********************************************************/
      fn_LoadingData() {
        if(!this.isFinished)  {
          this.fn_search()
        }
      },
    /*********************************************************
     * @function fn_changeSearch
     * @name     조회 조건 변경 시, 기존 데이터 초기화
     *********************************************************/
      fn_changeSearch() {
        this.cmGaCustMngVO = []  // 기존 조회 데이터 초기화
        this.nextKey = '0'       // nextkey 초기화
        this.isFinished = false
        this.fn_search()
      },
    /*********************************************************
     * @function fn_ClickBtnPrevious
     * @name     
     *********************************************************/
      fn_ClickBtnPrevious () {
        this.$router.go(-1)
      },
    }
  };
</script>